import {
	readClosedDealsCount,
	readClosedDealsPage,
} from '../../infrastructure/store';
import { CLOSED_DEALS_LIMIT } from '../constants';
import { hasNextPage } from '../domain';

export const useHasNextClosedDealsPage = () => {
	return computed(() => {
		const count = readClosedDealsCount();

		if (!count) {
			return false;
		}
		else {
			const page = readClosedDealsPage();

			return hasNextPage({
				limit: CLOSED_DEALS_LIMIT,
				page,
				count,
			});
		}
	});
};
