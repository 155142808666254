import { apiMethods } from '../../infrastructure/api';
import {
	readClosedDealsItems,
	readClosedDealsPage,
	saveClosedDeals,
	saveClosedDealsPage,
} from '../../infrastructure/store';

export const useNextClosedDealsPage = () => {
	const nextClosedDealsPage = apiMethods.useGetClosedDeals();

	return async () => {
		const currentPage = readClosedDealsPage();
		const nextPage = currentPage + 1;

		const result = await nextClosedDealsPage(nextPage);

		if (result.isRight()) {
			const { items, count } = result.value;

			const currentItems = readClosedDealsItems();
			saveClosedDeals({
				items: [...(currentItems || []), ...items],
				count,
			});
			saveClosedDealsPage(nextPage);
		}
	};
};
