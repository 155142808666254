<script setup lang="ts">
import { BaseFetchWrapper, useAsyncState } from '@legacy-studio/library';
import { useElementVisibility } from '@vueuse/core';
import {
	useHasNextClosedDealsPage,
	useGetClosedDeals,
	useNextClosedDealsPage,
} from '../core/application';
import { getClosedDealsSections, groupDealsSections } from '../core/domain';
import { saveClosedDealsPage, useClosedDeals } from '../infrastructure/store';
import ClosedDealsSection from './ClosedSection.vue';
import DealsSectionTemplate from './SectionTemplate.vue';
import { UiCoverLoader } from '@/src/shared/ui';
import { useActiveInvoice } from '~/src/features/invoices';

const hasNextClosedDealsPage = useHasNextClosedDealsPage();
const closedDeals = useClosedDeals();
const closedDealsGroups = computed(() =>
	groupDealsSections(getClosedDealsSections(closedDeals.value)),
);

const { pending, error, refresh } = useAsyncData(useGetClosedDeals());
const {
	isLoading: nextPagePending,
	error: nextError,
	execute: nextPage,
} = useAsyncState(useNextClosedDealsPage());

const target = ref(null);
const targetIsVisible = useElementVisibility(target);

watch(targetIsVisible, (isVisible) => {
	if (isVisible && !nextPagePending.value && !nextError.value) {
		nextPage();
	}
});
const activeInvoice = useActiveInvoice();
watch(activeInvoice, () => {
	refresh();
});

onBeforeUnmount(() =>
	saveClosedDealsPage(1),
);
</script>

<template>
	<DealsSectionTemplate title="Завершенные сделки">
		<BaseFetchWrapper
			:pending="pending"
			:error="error || nextError"
		>
			<div
				v-for="group in closedDealsGroups"
				:key="group.title"
			>
				<div
					v-if="group.title"
					class="py-3 text-xs font-normal text-gray-500"
				>
					{{ group.title }}
				</div>

				<ClosedDealsSection
					v-for="(section, idx) in group.sections"
					:key="idx"
					:data="section"
				/>
			</div>

			<div
				v-if="hasNextClosedDealsPage"
				class="relative p-10"
			>
				<UiCoverLoader v-if="nextPagePending" />

				<div
					v-if="hasNextClosedDealsPage"
					ref="target"
				/>
			</div>
		</BaseFetchWrapper>
	</DealsSectionTemplate>
</template>
