<script setup lang="ts">
import { formatAmount } from '@legacy-studio/library';
import { useOpenDealsSection } from '../core/application';
import type { DealsSection } from '../core/types';

defineProps<{
	data: DealsSection;
}>();

const openDealsSection = useOpenDealsSection();
</script>

<template>
	<div
		class="grid grid-cols-3 gap-[14px] py-3 border-b border-gray-600 cursor-pointer"
		@click="openDealsSection(data)"
	>
		<div class="flex flex-col">
			<span class="text-xs font-medium text-white">
				{{ data.stringifiedTime }}
			</span>

			<span class="text-xs font-medium text-gray-500">
				{{ data.items.length }} сдел.
			</span>
		</div>

		<div class="flex flex-col">
			<span class="text-xs font-medium text-white">
				{{ data.asset }}
			</span>

			<span class="text-xs font-medium flex items-center gap-1.5">
				<span class="text-green-400">
					{{ `${data.percent}%` }}
				</span>

				<span class="text-gray-500">{{
					data.type === "short" ? "1-5 мин." : "5+ мин."
				}}</span>
			</span>
		</div>

		<div class="flex flex-col items-end">
			<span class="text-xs font-medium text-white">
				{{ formatAmount(data.sum) }}
			</span>

			<span
				class="text-xs font-medium"
				:class="{
					'text-green-400': !!data.income,
					'text-red-400': !data.income,
				}"
			>
				{{ formatAmount(data.income) }}
			</span>
		</div>
	</div>
</template>
