export const hasNextPage = ({
	limit,
	page,
	count,
}: {
	limit: number;
	page: number;
	count: number;
}) => {
	return count > limit * page;
};
