import { apiMethods } from '../../infrastructure/api';
import {
	readClosedDealsPage,
	saveClosedDeals,
} from '../../infrastructure/store';

export const useGetClosedDeals = () => {
	const getClosedDeals = apiMethods.useGetClosedDeals();

	return async () => {
		const page = readClosedDealsPage();
		const result = await getClosedDeals(page);

		if (result.isRight()) {
			saveClosedDeals(result.value);
		}
		else {
			throw result.value;
		}
	};
};
